._ {
  width: 660px;
  margin: 0 auto;
  padding: 20px 0;
}

._title {
  font-weight: 500;
}

.yearly {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0;

  i {
    font-size: 12px;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    margin-right: 10px;
    display: block;
  }
}

.plans {
  display: inline-flex;
  align-items: center;
  margin: 50px 0 0 0;

  border-radius: 3px;
}

.plan {
  width: 220px;
  border: 1px solid #fff;
  border-top: 1px solid #e3e3e5;
  border-bottom: 1px solid #e3e3e5;
  border-right: 1px solid #e3e3e5;
  padding: 20px 20px;
  position: relative;

  &:last-of-type {
    // border: none;
  }
  &:first-of-type {
    // border: none;
    border-left: 1px solid #e3e3e5;
  }
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid #999;
  }
}

.selected {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid #333;
  border-left: 1px solid #333;
  &:first-of-type {
    // border: none;
    border-left: 1px solid #333;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid #333;
    border-left: 1px solid #333;
  }
}

.popular {
  position: absolute;
  height: 24px;
  top: -25px;
  left: 0;
  background: #333;
  color: white;
  font-size: 12px;
  width: 219px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.name {
  font-size: 18px;
  font-weight: 500;
  margin: 0;

  b {
    font-weight: 600;
  }
}

.desc {
  opacity: 0.7;
  font-size: 13px;
  margin: 5px 0;
}

.prices {
  text-align: center;
  margin: 50px 0 40px 0;
}

.pricel {
  display: block;
  margin-bottom: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #333;
}

.pricem {
  display: block;
  text-align: center;
  font-size: 13px;
  padding-top: 20px;
  font-weight: 500;
  color: #333;
}
.price {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0;

  small {
    font-size: 16px;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    padding-left: 5px;
  }
}

.functions {
  display: inline-flex;
  align-items: center;
  margin: 50px 0;
}

.col {
  width: 220px;
}

.col-title {
  font-size: 15px;
  font-weight: 600;
}

.info {
  padding-top: 0;
  margin-top: 20px;
  margin-bottom: 0;

  font-size: 15px;
  color: #666;

  font-weight: 500;

  b {
    color: #000;
    font-weight: 600;
  }
}

._ {
  margin: 8px 0 20px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;

  // margin-top: -42px;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.phone-code {
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 15px;
  pointer-events: none;
  font-size: 15px;

  img {
    margin-right: 8px;
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
  }
}

.input {
  border: none;
  /* border-bottom: 2px solid #e3e3e5; */
  width: 100%;
  height: 44px;
  /* border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; */
  border-radius: 4px;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  display: flex;
  align-items: center;
  color: black;
  /* background: #fafafa; */
  transition: 150ms ease-in-out all;
  font-family: 'Montserrat' !important;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.15);

  &::placeholder {
    color: lightgrey;
  }

  &:focus {
    box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.3);
  }
}

.phone {
  padding-left: 85px;
}

.message {
  padding: 15px 20px;
  min-height: 120px;
  max-height: 120px;
  height: 120px;
  min-width: 315px;
  resize: none;
  &:focus {
    box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.2);
  }
}

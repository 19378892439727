._ {
  width: 375px;
  // margin-bottom: 80px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  overflow: hidden;
  transition: 200ms ease-in-out;
  display: flex;
  align-items: center;
  padding: 15px;
  flex-shrink: 0;
  position: relative;
  margin: 30px 0;
}

.image {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-position: center center;
  background-size: cover;
  border: 2px solid white;
  box-shadow: 0 0 0 1px black;
  overflow: hidden;
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.02),
    rgba(0, 0, 0, 0.12)
  );
}

.top {
  background: #f3f3f5;
  margin: 0;
  font-size: 14px;
  padding: 1px 4px;
  font-weight: 500;
  color: #000;
}

.text {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  margin-top: 3px;
  margin-left: 4px;
  color: #333;
  // margin-bottom: 20px;
}

.bottom {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-left: 4px;
}

.time {
  margin: 0;
  font-size: 11px;
  padding: 1px 0px;
  font-weight: 500;
  color: #999;
}

.credit {
  // position: absolute;
  // right: 12px;
  // bottom: 8px;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
  font-size: 10px;

  font-weight: 500;
  color: #000;

  transition: 200ms ease-in-out all;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.check {
  background: #000;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.wrapper {
  background: white;
  width: 540px;
  // min-height: 200px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
  margin-top: -150px;
}

.header {
  font-size: 19px;
  color: #333;
  font-weight: 500;
  padding: 20px 25px;
  // border-bottom: 1px solid #f3f3f5;
  display: flex;
  align-items: center;
}

.close {
  position: absolute;
  right: 25px;
  cursor: pointer;
  opacity: 0.5;
}

.body {
  padding: 5px 25px 30px 25px;

  p {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
}

._ {
}

.inner {
  padding: 20px 30px 40px 30px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.19)
  );
}

.back {
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 600;
  margin-left: -4px;
  cursor: pointer;
  transition: all 200ms ease-in;

  svg {
    margin-right: 6px;
  }

  &:hover {
    opacity: 0.6;
  }
}

.header {
  font-family: 'Montserrat';
  color: white;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  // text-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
}

.text {
  font-family: 'Montserrat';
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8;
}

.social {
  margin-top: 20px;

  a {
    color: white;
  }

  svg {
    width: 20px;
    margin-right: 10px;
  }
}

@keyframes showMessage {
  from {
    transform: translateY(-1000px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes hideMessage {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-1000px);
    opacity: 0.5;
  }
}

._ {
  background: white;
  display: grid;
  grid-template-columns: 28px 1fr;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  animation: showMessage 300ms ease-in-out, hideMessage 300ms 6200ms ease-in-out;
  transition: 200ms ease-in-out all;
  z-index: 2000 !important;
}

.icon {
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.text {
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #454545;
}

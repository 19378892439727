.wrapper {
  margin: 36px 0 0 0;
  position: relative;
  padding-top: 14px;
}

.label {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0;
}

.input {
  margin-top: 6px;
  height: 38px;
  width: 100%;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  appearance: none;
  transition: 100ms linear;
  margin-bottom: 6px;
  color: #333;
  font-weight: 500;

  &::placeholder {
    color: #ccc;
    font-weight: 400;
  }

  &:focus {
    border: 2px solid #333;
    padding: 0 9px;

    & + .label {
      color: #333;
      font-weight: 600;
    }
  }
}

.no-margin {
  margin: 0;

  .input {
    margin: 6px 0 0 0;
  }
}

.input-error {
  // border-color: #f20c63;
  border-bottom: 1px solid #f20c63;

  &:focus {
    border: 1px solid #d3d3d5;
    border-bottom: 2px solid #f20c63;
  }
}

.error {
  position: absolute;
  color: #f20c63;
  font-size: 12px;
  left: 3px;
  top: 65px;
  font-weight: 500;
}

._ {
  // border: 1px solid black;
}

.head {
  display: flex;
  align-items: center;
}

.card {
  padding: 10px 30px;
  // border: 1px solid #e3e3e5;
  position: relative;
  cursor: pointer;
  min-width: 230px;
  width: 33.333%;
  transition: 200ms ease-in-out;
  border-radius: 3px;
  border: 1px solid #fff;

  &:hover {
    background: #f3f3f5;
  }
}
.card-selected {
  border: 1px solid #0066ff;
  background: rgba(0, 111, 225, 0.12);
  &:after {
    // content: '';
    width: 100%;
    height: 2px;
    background: black;
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  &:hover {
    background: rgba(0, 111, 225, 0.12);
  }

  .card-label {
    color: rgb(63, 66, 78);
    font-weight: 500;
  }
  .card-value {
    font-weight: 700;
    color: rgb(28, 31, 43);
  }
}

.card-label {
  font-size: 13px;
  font-weight: 500;
  color: rgb(153, 153, 153);
  margin: 5px 0;
}

.card-value {
  font-weight: 500;
  font-size: 26px;
  padding-left: 1px;
  margin-bottom: 10px;
}

.chart {
  margin-top: 20px;
}

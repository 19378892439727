.header {
  display: flex;
  margin-top: 30px;

  .div {
    width: 30px;
  }
}

.dates {
  margin-top: 36px;
  margin-right: 30px;
  margin-left: 30px;
  width: 220px;

  label {
    display: block;
    font-weight: 500;
    padding-left: 3px;
    font-size: 12px;
    color: #666;
  }
}

.table {
  width: 100%;
  margin-top: 50px;

  th {
    padding: 10px 0;
    font-size: 12px;
    color: #333;
    opacity: 0.5;
    font-weight: 600;
  }

  td {
    padding: 20px 0;
    border-top: 1px solid #f3f3f5;
    font-size: 13px;
    font-weight: 500;
    color: #333;
  }
}

.status {
  display: flex;
}

.status-icon {
  margin-right: 15px;
}

.type {
}

.type-type {
  font-size: 13px;
}

.type-text {
  opacity: 0.5;
  font-size: 12px;
  margin-top: 3px;
}

.operator {
  display: flex;
  align-items: center;
}

.operator-data {
  margin-left: 15px;
}

.operator-name {
  font-size: 13px;
}
.operator-phone {
  font-size: 13px;
  opacity: 0.5;
  font-size: 12px;
  margin-top: 3px;
}
.empty {
  width: 200px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;

  svg {
    color: #d3d3d5;
    margin-bottom: 10px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    opacity: 0.5;
  }
}

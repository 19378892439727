._ {
  padding-left: 30px;
}

.header {
  height: 180px;
  position: relative;
  background-position: center center;
  background-size: cover;
}

.header-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(255, 255, 255, 0.6) 100%
  );
  padding: 30px;
}

.lead {
  font-size: 21px;
  font-weight: 600;
  margin: 30px 0;
}

._stats {
  display: flex;
  align-content: center;
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  flex-wrap: wrap;
}

.stats {
  display: flex;
  align-content: center;
  margin-right: 10px;
  margin-top: 10px;

  svg {
    margin: 10px;
    color: #333;
  }
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.stats-icon {
  display: flex;
  align-content: center;
  height: 100%;
  margin-right: 10px;
}

.stats-label {
  font-size: 10px;
  font-weight: 600;
  color: #666;
  margin-top: 5px;
}

.stats-value {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.table {
  width: 100%;
  margin: 20px 0;
}

.label {
  width: 180px;
  text-transform: uppercase;
  color: #999;
  font-size: 12px;
  padding: 10px;
  vertical-align: top;
}

.value {
  font-size: 15px;
  color: #333;
  padding: 10px;
  line-height: 24px;
}

._ {
  width: 64px;
  height: 64px;
  background: #e3e3e5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  color: #333;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.availabililty {
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 100%;

  right: 1.5%;
  bottom: 1.5%;
}

.ok {
  background: #79f1af;
}
.bad {
  background: #faa6a6;
}

._ {
  padding: 0px 30px;
}

.buttons {
  display: flex;
  align-items: center;
  margin: 0px 0;
}

.button {
  width: 33.3333%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  border-radius: 6px;
  transition: 200ms ease-in-out;
  color: #000;

  &:hover {
    background: #f3f3f5;

    .button-inner {
      svg,
      img {
        opacity: 0.8;
      }
    }

    .button-text {
      opacity: 0.8;
    }
  }
}

.button-inner {
  text-align: center;

  img,
  svg {
    text-align: center;
    opacity: 0.3;
    margin-bottom: 10px;
    transition: 200ms ease-in-out;
  }
}

.button-text {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.3;
  transition: 200ms ease-in-out;
}

.credit-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  width: 100%;
}
.credit {
  display: flex;
  align-items: center;
  font-size: 11px;

  font-weight: 500;
  color: #404040;

  transition: 200ms ease-in-out all;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  svg {
    margin-right: 5px;
  }
}

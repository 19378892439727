._ {
  width: 660px;
  margin: 0 auto;
  padding: 20px 0;
}

._title {
  font-weight: 500;
}

.row3 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

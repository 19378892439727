.title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-top: 50px;
  margin-bottom: -10px;
}

.form {
  width: 500px;
}

.row3 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.card-input {
  height: 38px;
  border: 1px solid #e3e3e5;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 0 15px;
}

.card-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  opacity: 0.9;
  padding-left: 2px;
  margin: 0px 0 8px 0;
}

.submit {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #f3f3f5;
}

.error {
  color: #f20c63;
  font-size: 12px;
  padding: 10px 0;
}

.cards {
  margin: 30px 0 0 0;
  width: 800px;

  td {
    padding: 10px 0;
  }
}

.card {
  display: flex;
  align-items: center;
  color: #666;
  font-weight: 500;
  font-size: 14px;

  img {
    margin-right: 10px;
  }

  small {
    font-weight: 600;
    color: #000;
    padding-left: 6px;
  }
}

.expiration {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.wrapper {
  display: flex;
  align-items: center;
  margin: 36px 0 0 0;
  position: relative;
}

.input {
  padding-left: 46px;
  &:focus {
    padding: 0 45px;
  }
}

.color {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
}

.handle {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #777;
  border-radius: 100%;
  margin-left: 13px;
}

.picker {
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 999999;
}

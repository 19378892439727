.header {
  display: flex;
  align-items: center;

  .button {
    margin-left: 20px;
    margin-top: 40px;
  }
}

.table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;

  th {
    text-align: left;
    padding-bottom: 10px;
    color: #777;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 20px;
  }

  td {
    font-size: 13px;
    color: #333;
    font-weight: 600;
    border-top: 1px solid #f3f3f5;
    padding: 20px 20px;

    small {
      font-weight: 500;
      opacity: 0.7;
    }
  }
}

.select {
  margin-left: -20px;
  max-width: 150px;
}

.user {
  display: flex;
  align-items: center;
}

.userc {
  margin-left: 20px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-bottom: 2px;
}

.email {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin: 0;
  margin-bottom: 4px;
}

.tag {
  background: #333;
  color: white;
  padding: 3px 10px;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 12px;
  font-weight: 600;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Inconsolata:400,600&subset=latin-ext');

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Open Sans', 'Lato', sans-serif;
  /* background: #f7f9fc; */
  max-width: 100%;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: rgb(0, 111, 255);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

hr {
  border: none;
  height: 1px;
  background: #e3e3e5;
  margin: 30px 0;
}

.app {
  display: grid;
  grid-template-columns: 260px 1fr;
  padding-right: 40px;
}

.messages {
  position: fixed;
  top: 0px;
  left: 50%;
  z-index: 2000;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  background: #e3e3e5;
  inset: none;
  appearance: none;
  border: none;
  margin: 30px 0;
}

.center-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

table {
  border-spacing: 0px;
}

/* 
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table td {
  border-top: 1px solid #f3f3f5;
  border-bottom: 1px solid #f3f3f5;
  padding: 10px 0;
} */

.DateRangePicker {
  margin-top: 6px;
  display: block;
}

.DateRangePickerInput {
  /* display: flex;
  align-items:  */
  display: flex;
  align-items: center;
}

.DateRangePickerInput__withBorder {
  border-color: #e3e3e5;
}

.DateInput {
  width: 100%;
}

.DateInput_input {
  height: 36px;
  font-size: 14px;
  color: #333;
}
.DateInput_input__focused {
  border-color: #333;
}

.braintree-hosted-fields-focused {
  border: 2px solid #333333 !important;
  padding: 0 14px;
}
.braintree-hosted-fields-invalid {
  border: 2px solid #f20c63 !important;
  padding: 0 14px;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-tooltip-wrapper {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 22px 1px rgba(0, 0, 0, 0.12);
}
.recharts-default-tooltip {
  border: 1px solid white !important;
  border-radius: 4px;
  padding: 7px 10px 0 10px !important;
}

.recharts-tooltip-label {
  font-size: 12px;
  font-weight: 500;
  color: #777;
}

.recharts-tooltip-item-list {
  margin-top: 10px;
}

.recharts-tooltip-item {
  font-size: 14px;
  font-weight: 600;
}

.recharts-legend-item-text {
  font-size: 13px;
  color: #666;
  font-weight: 500;
}

._ {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0;
}

.header {
  text-align: center;
  margin: 0px 0;
}

.title {
  font-weight: 500;
  font-size: 28px;
}

.wrapper {
  margin: 36px 0 0 0;
  position: relative;
  padding-top: 14px;
}

.label {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0;
}

.select {
  appearance: none;
  width: 100%;
  background: white;
  margin-top: 6px;
  height: 38px;
  width: 100%;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  appearance: none;
  transition: 100ms linear;
  margin-bottom: 16px;
  color: #333;

  &:focus {
    border: 2px solid #333;
    padding: 0 9px;
  }

  // &:focus + .chevron {
  //   transform: rotate(180deg);
  // }
}

.chevron {
  position: absolute;
  width: 20px;
  top: 28px;
  right: 12px;
  color: #c3c3c5;
  pointer-events: none;
}

.no-margin {
  margin: 0;
}

.no-label {
  .chevron {
    top: 15px;
  }
}

.size-small {
  height: 26px;
  font-size: 12px;
  font-weight: 500;
  margin-top: -20px;
  margin-left: 20px;
  min-width: 100px;

  .select {
    height: 26px;
    font-size: 12px !important;
    font-weight: 500;
    margin-top: 3px;
  }

  .chevron {
    top: 18px;
    width: 16px;
    right: 8px;
  }
}

.label-small {
  font-size: 14px;
}

.select-error {
  border-color: #f20c63;

  &:focus {
    border-color: #f20c63;
  }
}

.error {
  position: absolute;
  color: #f20c63;
  font-size: 12px;
  left: 3px;
  bottom: 0px;
  font-weight: 500;
}

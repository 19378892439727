.form {
  max-width: 400px;
}

.label {
  display: block;
  font-weight: 500;

  font-size: 14px;
  color: #666;
}

.avatar {
  margin: 20px 0 10px 0;
}

.info {
  color: #888;
  font-size: 12px;
  line-height: 18px;
  left: 3px;
  top: 65px;
  font-weight: 500;
  display: block;
  max-width: 400px;
  margin-top: 7px;
}

.form {
  max-width: 380px;
}

.label {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  margin-top: 30px;
}

.roles {
  display: grid;
  grid-template-columns: 180px 180px 180px;
  grid-gap: 20px;
  margin-top: 6px;
}

.role {
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.role-selected {
  border: 1px solid #333;
}

.role-check {
  position: absolute;
  background: #444;
  width: 23px;
  height: 23px;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 15px;
}

.role-name {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
  margin-bottom: 30px;
}

.role-feature {
  margin: 10px 0;
  font-size: 12px;
  font-weight: 500;
  color: #333;
}

.w {
  width: 100%;
}

._ {
  width: 100%;
  height: 44px;
  background: none;
  border: 2px solid #000;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 200ms ease-in-out;

  appearance: none;
  outline: none;
}

.active {
  background: #000;
  color: white;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3);
  }

  &:active {
    opacity: 1;
    transform: translateY(1px);
  }
}

.privacy {
  display: block;
  font-size: 12px;
  margin: 10px 3px;
  font-weight: 500;
  color: #666;
  a {
    font-size: 12px;
    font-weight: 600;
    color: #333;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

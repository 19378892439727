@keyframes in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

._ {
  width: 320px;
  margin-bottom: 80px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  // overflow: hidden;
  transition: 200ms ease-in-out;
  position: relative;

  animation: in 500ms forwards;
  background: white;
  box-sizing: border-box;
}

.wrapper {
  &:hover {
    .close {
      opacity: 1;
    }
  }
}

.close {
  display: flex;
  opacity: 0;
  margin-left: auto;
  margin-bottom: 10px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  transition: 400ms ease-in-out;
  z-index: 2147483647;
  box-sizing: border-box;
}

.head {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
}

.inner {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.05),
    rgba(0, 0, 0, 0.22)
  );
  box-sizing: border-box;
}

.operator {
  margin: 0;
  margin-left: 15px;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
}

.name {
  color: white;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin: 0 !important;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
  padding: 0 !important;
  line-height: 21px !important;
}
.more {
  color: white;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin: 0 !important;
  margin-top: 8px !important;
  margin-block-start: 0px !important;
  opacity: 0.9;
  box-sizing: border-box !important;
  font-family: 'Montserrat' !important;
  padding: 0 !important;
  line-height: 21px !important;
}

.content {
  padding: 20px 25px;
  box-sizing: border-box;
}
.header {
  font-size: 18px !important;
  color: #000 !important;
  margin: 0 !important;
  font-weight: 500;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
}

.text {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  color: #666 !important;
  margin-top: 10px;
  margin-bottom: 0;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
  padding: 0;
}

.avatar {
  width: 64px;
  height: 64px;
  background: #e3e3e5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  color: #333;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
}

@media only screen and (max-width: 400px) {
  ._ {
    width: 90%;
    margin: 30px 5% 60px 5%;
    border-radius: 12px;
  }

  .close {
    opacity: 1 !important;
  }
}

@media only screen and (max-width: 800px) {
  .close {
    opacity: 1 !important;
  }
}

.trial {
  text-align: center;
  width: 600px;
}

.trial-title {
  font-weight: 600;
  font-size: 21px;
}

.title {
  font-weight: 600;
  font-weight: 14px;
}

.table {
  width: 100%;
  margin-top: 30px;

  td,
  th {
    padding: 10px 5px;
  }

  td {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  th {
    font-weight: 500;
    color: #333;
    opacity: 0.5;
    font-size: 14px;
  }
}

._ {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 100px 0;
}

.title {
  font-size: 26px;
  font-weight: 500;
  color: #000;
  line-height: 32px;
  margin-top: 40px;
  margin-top: 60px;
}

.form {
  max-width: 420px;
}

.left {
  border-right: 1px solid #f3f3f5;
  margin-right: 30px;
}
.right {
  // border-left: 1px solid #f3f3f5;
  // padding-left: 30px;
  padding-top: 80px;
}

.subtitle {
  font-weight: 600;
  color: #000;
  line-height: 2.2rem;
  margin-bottom: 0;
}

.text {
  font-size: 15px;
  color: #aaa;
  font-weight: 500;
  margin-bottom: 34px;
  margin-top: 5px;
}

.list {
  list-style: none;
  padding: 5px 0 200px 0;

  li {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 500;
    color: #333;

    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: #19cda6;
    }
  }
}

.logos {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;

  img {
    // opacity: 0.8;
    margin-right: 30px;
  }
}

.terms {
  font-size: 12px;

  a {
    font-size: 12px;
    color: black;
    text-decoration: underline;
  }
}

.link {
  color: #666;
  margin-top: 13px;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    color: #000;
  }
}

.article {
  display: flex;
  align-items: center;
  margin-top: 35px;
  border-radius: 6px;
  overflow: hidden;
  transition: 200ms all linear;
  flex-shrink: 0;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
}

.article-image {
  width: 130px;
  height: 130px;
  img {
    height: 100%;
    width: 130px;
    object-fit: cover;
    object-position: center;
  }
}

.article-content {
  background: #f3fcfa;
  padding: 15px;
  span {
    color: #19cda6;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;

    svg {
      margin-right: 6px;
    }
  }

  h3 {
    margin: 3px 0;
    font-weight: 700;
    color: #000;
  }

  p {
    margin: 10px 0 0 0;
    font-size: 13px;
    color: #333;
    opacity: 0.9;
  }
}

@media screen and (max-width: 600px) {
  .rev {
    flex-direction: column-reverse;
  }

  .log {
    position: absolute;
    top: 30px;
    left: 10px;
  }

  .text {
    font-size: 14px;
  }

  .title {
    font-size: 21px;
  }

  .article-image {
    display: none;
  }

  .list {
    padding: 30px 0;
    li {
      font-size: 12px;
    }
  }

  .logos {
    position: relative;
    display: block;
    align-items: center;

    img {
      // opacity: 0.8;
      display: block;
      margin: 30px auto;
    }
  }

  .left {
    border: none;
  }
}

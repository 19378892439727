.label {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0;
}

.textarea {
  margin-top: 6px;
  // height: 38px;
  width: 100%;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 14px;
  outline: none;
  appearance: none;
  transition: 100ms linear;
  margin-bottom: 6px;
  color: #333;
  font-weight: 500;
  height: 75px;

  &::placeholder {
    color: #ccc;
    font-weight: 400;
  }

  &:focus {
    border: 2px solid #333;
    padding: 8px 9px;

    & + .label {
      color: #333;
      font-weight: 600;
    }
  }
}

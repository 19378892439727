.wrapper {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: #333;
  }

  input:focus + .slider {
    // box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(24px);
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 14px;

  &:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100%;
    z-index: 4;
  }
}

.check {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 2;
}

.x {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
}

.label {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 600;
  color: #aaa;
}

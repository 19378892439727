._ {
  bottom: 0;
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2147483647;
  transition: 200ms ease-in-out all;
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  transition: 200ms ease-in-out all;
  cursor: pointer;

  svg {
    transition: 200ms ease-in-out all;
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06))
      drop-shadow(0px 2px 32px rgba(0, 0, 0, 0.16));
  }

  &:hover {
    transform: translateY(-2px);
    opacity: 0.9;

    svg {
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.06))
        drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.26));
    }

    &:active {
      transform: translateY(1px);
      opacity: 1;
      svg {
        filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06))
          drop-shadow(0px 2px 32px rgba(0, 0, 0, 0.16));
      }
    }
  }
}

._ {
  width: 220px;
  border-right: 1px solid #f3f3f5;
  height: 100vh;
  padding: 0px 25px;
  position: fixed;
  top: 0;
  left: 0;
}

.head {
  height: 80px;
  display: flex;
  align-items: center;
}

.link {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  opacity: 0.45;
  transition: 200ms ease-in-out;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    width: 0px;
    height: 3px;
    background: black;
    position: absolute;
    left: -25px;
    transition: 200ms ease-in-out;
  }

  &:hover {
    opacity: 0.85;
  }
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: #000;
}

.text {
  font-size: 14px;
  color: #000;
}

.active {
  opacity: 1;

  .text {
    font-weight: 600;
  }

  &:before {
    content: '';
    width: 15px;
    height: 3px;
    background: black;
    position: absolute;
    left: -25px;
    transition: 200ms ease-in-out;
  }

  &:hover {
    opacity: 1;
  }
}
.profile {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.userc {
  margin-left: 15px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-bottom: 3px;
}

.email {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin: 0;
}

.logout {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  transition: 500ms ease-in-out all;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: 600;

  svg {
    margin-right: 10px;
  }
}

.user {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  // margin-left: 10px;
  position: relative;
  cursor: pointer;
  transition: 300ms ease-in-out all;

  &:hover {
    .logout {
      opacity: 1;
      margin-left: -44px;
    }
  }
}

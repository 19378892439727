.btn {
  border: none;
  border-radius: 3px;
  padding: 0 24px;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  transition: 200ms ease-in-out;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  overflow: hidden;

  svg {
    margin-right: 10px;
  }
}

.btn-full-width {
  width: 100%;
}

.btn-primary {
  background: #333;
  color: white;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}
.btn-plan {
  background: #2976e9;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
  color: white;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}
.btn-danger {
  background: #f5222d;
  color: white;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.btn-default {
  background: #e3e3e5;
  color: #333;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.btn-disabled {
  // background: none !important;
  // border: 2px solid #e3e3e5 !important;
  // background:
  // color: #e3e3e5;
  opacity: 0.7;
  cursor: not-allowed;

  &:hover {
    opacity: 0.7;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    animation: spin 2000ms linear infinite;
  }
}

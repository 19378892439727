.wrapper {
  margin: 20px 0;
  padding: grid;
}

.cell {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e3e3e5;
  background: white;
  position: relative;

  &:first-of-type {
    border-top: 1px solid #e3e3e5;
  }
}

.dragging {
  border-top: 1px solid #e3e3e5;
}

.index {
  background: #333;
  width: 24px;
  height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100%;
  margin-right: 15px;
  margin-left: 15px;
}

.user {
  display: flex;
  align-items: center;
}

.userc {
  margin-left: 20px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-bottom: 2px;
}

.email {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin: 0;
  margin-bottom: 4px;
}

.trial {
  text-align: center;
  width: 600px;
}

.trial-title {
  font-weight: 600;
  font-size: 21px;
}

._ {
  width: 400px;
}

.title {
  font-weight: 600;
  font-weight: 14px;
}

.info {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  opacity: 0.5;
}

.text {
  font-size: 13px;
  color: #999;
  font-weight: 500;
  line-height: 18px;
  opacity: 1;

  a {
    font-size: 13px;
  }
}

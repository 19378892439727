.row {
  display: flex;
  margin: 40px 0;
}

.info {
  margin-right: 100px;
}

.labels {
  display: flex;
  align-items: center;
}

.right {
  margin-left: auto;
  text-align: right;
}

.label {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.7;
}

.value {
  font-size: 28px;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: 15px;
}

.bar {
  width: 300px;
  height: 16px;
  border-radius: 3px;
  background: #e3e3e5;
  margin: 10px 0 0 0;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #333;
}

.small {
  display: block;
  font-size: 12px;
  color: #333;
  opacity: 0.6;
  padding-top: 6px;
  font-weight: 500;
}

.card {
  display: inline-block;
  border: 1px solid #e3e3e5;
  margin: 30px 0;
  // width: 450px;
  border-radius: 3px;
  padding: 10px 60px 30px 60px;
  // margin: 80px 0;
  text-align: center;
}

.title {
  font-size: 21px;
  font-weight: 600;
  margin: 20px 0;
  color: #333;
}

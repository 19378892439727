._ {
  width: 660px;
  margin: 0 auto;
  padding: 20px 0;
}

._title {
  font-weight: 500;
}

.card-input {
  height: 38px;
  border: 1px solid #e3e3e5;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 0 15px;
}

.card-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  opacity: 0.9;
  padding-left: 2px;
  margin: 0px 0 8px 0;
}

.submit {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #f3f3f5;
}

.error {
  color: #f20c63;
  font-size: 12px;
  padding: 10px 0;
}

.map {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-position: center center;
  cursor: pointer;
  transition: 200ms ease-in-out;
  padding: 20px 30px;

  svg {
    margin-bottom: 16px;
    color: black;
  }

  &:hover {
    // opacity: 0.7;
  }
}

._ {
  margin: 30px 0;
}

.title {
  font-weight: 600;
  font-size: 17px;
}

.subtitle {
  font-size: 14px;
  color: #999;
  font-weight: 500;
  margin-top: 5px;
}

.content {
  margin-top: 25px;
}

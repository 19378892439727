.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.map {
  height: 150px;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  margin-bottom: 50px;
  margin-top: 30px;
  border-radius: 3px;
}
.dot {
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 100%;
  background: rgb(0, 111, 255);
  border-width: 3px;
  border-style: solid;
  border-color: rgb(91, 160, 249);
  box-shadow: 0 0 0 2px rgb(189, 215, 250);
}

.city {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 11px;
  font-weight: 500;

  box-shadow: rgba(0, 111, 255, 0.3) 0px 1px 3px;
  background: white;
  padding: 1px 7px;
  border-radius: 3px;
}

.map-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 20px;

  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.4) 20%,
    rgba(255, 255, 255, 0) 100%
  );
}
.title {
  // padding: 20px 10px;
  font-size: 24px;
  font-weight: 600;
}

.number {
  font-size: 18px;
  font-weight: 600;
  margin-top: 27px;
  margin-bottom: 11px;
  // text-shadow: 0 0 1px rgba(255, 255, 255, 1);

  span {
    font-size: 13px;
    opacity: 0.9;
    font-weight: 500;
  }
}

.actions {
  // margin-top: 58px;

  button {
    margin-right: 20px;
  }
}

.stat {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.icon {
  margin-right: 20px;
  color: #0066ff;
}

.content {
}

.label {
  font-size: 12px;
  font-weight: 600;
  color: rgb(99, 112, 152);
}

.value {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}

.subtitle {
  font-weight: 600;
  margin: 0;
  font-size: 18px;
}

.recording {
  // mar
  max-width: 600px;
  margin-bottom: 50px;
}

.blocked {
  background: rgb(255, 0, 111);
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 4px;
}

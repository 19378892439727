._ {
}

.grid {
  display: grid;
  grid-template-columns: 340px 1fr;
}

.header {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;

  .div {
    width: 30px;
  }
}

.dates {
  margin-top: 36px;
  margin-right: 30px;
  margin-left: 30px;
  width: 220px;

  label {
    display: block;
    font-weight: 500;
    padding-left: 3px;
    font-size: 12px;
    color: #666;
  }
}

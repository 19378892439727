.links {
  margin: 40px 0;
}

.link {
  padding: 10px 25px 10px 10px;
  border-bottom: 2px solid #e3e3e5;
  color: #999;
  font-weight: 600;
  transition: 200ms ease-in-out;

  svg {
    margin-right: 6px;
  }
}

.active {
  border-color: #000;
  border-width: 3px;
  color: #333;
  // font-weight: 600;
}

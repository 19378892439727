._ {
  width: 100%;
  th {
    color: #666;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 20px;
  }

  td {
    border-top: 1px solid #e3e3e5;
    padding: 10px 0;
    font-size: 13px;
    color: #333;
    font-weight: 500;
  }
}

@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Inconsolata:400,600&subset=latin-ext);
.button_btn__3Eo3S {
  border: none;
  border-radius: 3px;
  padding: 0 24px;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  transition: 200ms ease-in-out;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  height: 34px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  overflow: hidden; }
  .button_btn__3Eo3S svg {
    margin-right: 10px; }

.button_btn-full-width__evxNs {
  width: 100%; }

.button_btn-primary__2YM53 {
  background: #333;
  color: white; }
  .button_btn-primary__2YM53:hover {
    opacity: 0.8; }
  .button_btn-primary__2YM53:active {
    opacity: 1; }

.button_btn-plan__3abMf {
  background: #2976e9;
  height: 30px;
  font-size: 13px;
  font-weight: 600;
  color: white; }
  .button_btn-plan__3abMf:hover {
    opacity: 0.8; }
  .button_btn-plan__3abMf:active {
    opacity: 1; }

.button_btn-danger__3YF3S {
  background: #f5222d;
  color: white; }
  .button_btn-danger__3YF3S:hover {
    opacity: 0.8; }
  .button_btn-danger__3YF3S:active {
    opacity: 1; }

.button_btn-default__1UB99 {
  background: #e3e3e5;
  color: #333; }
  .button_btn-default__1UB99:hover {
    opacity: 0.8; }
  .button_btn-default__1UB99:active {
    opacity: 1; }

.button_btn-disabled__2U0De {
  opacity: 0.7;
  cursor: not-allowed; }
  .button_btn-disabled__2U0De:hover {
    opacity: 0.7; }

@-webkit-keyframes button_spin__10alZ {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes button_spin__10alZ {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.button_loader__3PK7c {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .button_loader__3PK7c svg {
    -webkit-animation: button_spin__10alZ 2000ms linear infinite;
            animation: button_spin__10alZ 2000ms linear infinite; }

.sidebar____1hECu {
  width: 220px;
  border-right: 1px solid #f3f3f5;
  height: 100vh;
  padding: 0px 25px;
  position: fixed;
  top: 0;
  left: 0; }

.sidebar_head__8lGUQ {
  height: 80px;
  display: flex;
  align-items: center; }

.sidebar_link__1joy0 {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  opacity: 0.45;
  transition: 200ms ease-in-out;
  position: relative;
  cursor: pointer; }
  .sidebar_link__1joy0:before {
    content: '';
    width: 0px;
    height: 3px;
    background: black;
    position: absolute;
    left: -25px;
    transition: 200ms ease-in-out; }
  .sidebar_link__1joy0:hover {
    opacity: 0.85; }

.sidebar_icon__-KTsI {
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: #000; }

.sidebar_text__14Veg {
  font-size: 14px;
  color: #000; }

.sidebar_active__3ABgv {
  opacity: 1; }
  .sidebar_active__3ABgv .sidebar_text__14Veg {
    font-weight: 600; }
  .sidebar_active__3ABgv:before {
    content: '';
    width: 15px;
    height: 3px;
    background: black;
    position: absolute;
    left: -25px;
    transition: 200ms ease-in-out; }
  .sidebar_active__3ABgv:hover {
    opacity: 1; }

.sidebar_profile__3epB4 {
  position: absolute;
  bottom: 0px;
  width: 100%; }

.sidebar_userc__B_Nsh {
  margin-left: 15px; }

.sidebar_name__n7tAr {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-bottom: 3px; }

.sidebar_email__W4ZFT {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin: 0; }

.sidebar_logout__BRAtl {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  transition: 500ms ease-in-out all;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600; }
  .sidebar_logout__BRAtl svg {
    margin-right: 10px; }

.sidebar_user__Hk1j1 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  transition: 300ms ease-in-out all; }
  .sidebar_user__Hk1j1:hover .sidebar_logout__BRAtl {
    opacity: 1;
    margin-left: -44px; }

.avatar____NaBGa {
  width: 64px;
  height: 64px;
  background: #e3e3e5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
  background-position: center center;
  background-size: cover;
  position: relative; }

.avatar_availabililty__3hc7J {
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 100%;
  right: 1.5%;
  bottom: 1.5%; }

.avatar_ok__1SKcA {
  background: #79f1af; }

.avatar_bad__2ndEy {
  background: #faa6a6; }

.switch_wrapper__2XQD8 {
  display: flex;
  align-items: center; }

.switch_switch__3W7z7 {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px; }
  .switch_switch__3W7z7 input {
    opacity: 0;
    width: 0;
    height: 0; }
  .switch_switch__3W7z7 input:checked + .switch_slider__2ld7o {
    background-color: #333; }
  .switch_switch__3W7z7 input:checked + .switch_slider__2ld7o:before {
    transform: translateX(24px); }

.switch_slider__2ld7o {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 14px; }
  .switch_slider__2ld7o:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 100%;
    z-index: 4; }

.switch_check__vpBHE {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 2; }

.switch_x__-SFQI {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2; }

.switch_label__1D8G8 {
  font-size: 12px;
  margin-left: 10px;
  font-weight: 600;
  color: #aaa; }

@-webkit-keyframes message_showMessage__hqSOD {
  from {
    transform: translateY(-1000px);
    opacity: 0.5; }
  to {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes message_showMessage__hqSOD {
  from {
    transform: translateY(-1000px);
    opacity: 0.5; }
  to {
    transform: translateY(0px);
    opacity: 1; } }

@-webkit-keyframes message_hideMessage__22cMG {
  from {
    transform: translateY(0px);
    opacity: 1; }
  to {
    transform: translateY(-1000px);
    opacity: 0.5; } }

@keyframes message_hideMessage__22cMG {
  from {
    transform: translateY(0px);
    opacity: 1; }
  to {
    transform: translateY(-1000px);
    opacity: 0.5; } }

.message____3yVzT {
  background: white;
  display: grid;
  grid-template-columns: 28px 1fr;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.12);
  margin-top: 15px;
  -webkit-animation: message_showMessage__hqSOD 300ms ease-in-out, message_hideMessage__22cMG 300ms 6200ms ease-in-out;
          animation: message_showMessage__hqSOD 300ms ease-in-out, message_hideMessage__22cMG 300ms 6200ms ease-in-out;
  transition: 200ms ease-in-out all;
  z-index: 2000 !important; }

.message_icon__3qD6u {
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%; }

.message_text__mh6X4 {
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #454545; }

.body____AP8Qu {
  width: 375px;
  margin-bottom: 80px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.17);
  border-radius: 12px;
  overflow: hidden;
  transition: 200ms ease-in-out; }

.top_inner__2-Czl {
  padding: 20px 30px 40px 30px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.19)); }

.top_back__14Bf9 {
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-weight: 600;
  margin-left: -4px;
  cursor: pointer;
  transition: all 200ms ease-in; }
  .top_back__14Bf9 svg {
    margin-right: 6px; }
  .top_back__14Bf9:hover {
    opacity: 0.6; }

.top_header__3D6Ls {
  font-family: 'Montserrat';
  color: white;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px; }

.top_text__3yhSf {
  font-family: 'Montserrat';
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.8; }

.top_social__eWjYn {
  margin-top: 20px; }
  .top_social__eWjYn a {
    color: white; }
  .top_social__eWjYn svg {
    width: 20px;
    margin-right: 10px; }

.input____2Kyp1 {
  margin: 8px 0 20px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-shrink: 0; }

.input_phone-code__1IgYB {
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 15px;
  pointer-events: none;
  font-size: 15px; }
  .input_phone-code__1IgYB img {
    margin-right: 8px;
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1); }

.input_input__3r7t0 {
  border: none;
  /* border-bottom: 2px solid #e3e3e5; */
  width: 100%;
  height: 44px;
  /* border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; */
  border-radius: 4px;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  display: flex;
  align-items: center;
  color: black;
  /* background: #fafafa; */
  transition: 150ms ease-in-out all;
  font-family: 'Montserrat' !important;
  box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.15); }
  .input_input__3r7t0::-webkit-input-placeholder {
    color: lightgrey; }
  .input_input__3r7t0:-ms-input-placeholder {
    color: lightgrey; }
  .input_input__3r7t0::-ms-input-placeholder {
    color: lightgrey; }
  .input_input__3r7t0::placeholder {
    color: lightgrey; }
  .input_input__3r7t0:focus {
    box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.3); }

.input_phone__2XYGM {
  padding-left: 85px; }

.input_message__9VYTm {
  padding: 15px 20px;
  min-height: 120px;
  max-height: 120px;
  height: 120px;
  min-width: 315px;
  resize: none; }
  .input_message__9VYTm:focus {
    box-shadow: -1px 2px 12px rgba(0, 0, 0, 0.2); }

.content____U9BXq {
  margin-top: -32px;
  padding: 0 30px;
  position: relative;
  min-height: 150px; }

.button_w__3kyvE {
  width: 100%; }

.button____3Ea6R {
  width: 100%;
  height: 44px;
  background: none;
  border: 2px solid #000;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: 200ms ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none; }

.button_active__3EGA1 {
  background: #000;
  color: white;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2); }
  .button_active__3EGA1:hover {
    opacity: 0.8;
    transform: translateY(-1px);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.3); }
  .button_active__3EGA1:active {
    opacity: 1;
    transform: translateY(1px); }

.button_privacy__3Nzvc {
  display: block;
  font-size: 12px;
  margin: 10px 3px;
  font-weight: 500;
  color: #666; }
  .button_privacy__3Nzvc a {
    font-size: 12px;
    font-weight: 600;
    color: #333;
    cursor: pointer; }
    .button_privacy__3Nzvc a:hover {
      opacity: 0.7; }

.bottom____1rCt5 {
  padding: 0px 30px; }

.bottom_buttons__qIDz3 {
  display: flex;
  align-items: center;
  margin: 0px 0; }

.bottom_button__3iktg {
  width: 33.3333%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 0;
  border-radius: 6px;
  transition: 200ms ease-in-out;
  color: #000; }
  .bottom_button__3iktg:hover {
    background: #f3f3f5; }
    .bottom_button__3iktg:hover .bottom_button-inner__3xOXg svg,
    .bottom_button__3iktg:hover .bottom_button-inner__3xOXg img {
      opacity: 0.8; }
    .bottom_button__3iktg:hover .bottom_button-text__3RAPc {
      opacity: 0.8; }

.bottom_button-inner__3xOXg {
  text-align: center; }
  .bottom_button-inner__3xOXg img,
  .bottom_button-inner__3xOXg svg {
    text-align: center;
    opacity: 0.3;
    margin-bottom: 10px;
    transition: 200ms ease-in-out; }

.bottom_button-text__3RAPc {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.3;
  transition: 200ms ease-in-out; }

.bottom_credit-wrapper__7tbK2 {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
  width: 100%; }

.bottom_credit__I0mJW {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #404040;
  transition: 200ms ease-in-out all;
  cursor: pointer; }
  .bottom_credit__I0mJW:hover {
    opacity: 0.5; }
  .bottom_credit__I0mJW svg {
    margin-right: 5px; }

.widget_header__2t268 {
  display: flex;
  align-items: center; }
  .widget_header__2t268 .widget_switch__pY5hi {
    margin-top: 44px;
    margin-left: 20px; }

.widget_grid__3A3yY {
  display: grid;
  grid-template-columns: 1fr 450px; }

.widget_preview__DqTgj {
  border-left: 1px solid #f3f3f5;
  border-bottom: 1px solid #f3f3f5;
  border-right: 1px solid #f3f3f5;
  height: 83vh;
  position: relative; }

.widget_link-row__2HidC {
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.widget_row-title__AUvu6 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  border-bottom: 1px solid #e3e3e5;
  padding: 10px 0;
  margin-right: 40px;
  opacity: 0.5; }

.widget_link__1oB-X {
  display: block;
  text-align: center;
  margin: 20px 40px 20px 0;
  color: #333;
  padding: 20px 10px;
  border-radius: 3px;
  transition: 100ms all linear;
  position: relative; }
  .widget_link__1oB-X svg {
    opacity: 0.9;
    margin-bottom: 10px; }
  .widget_link__1oB-X span {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    opacity: 0.5; }
  .widget_link__1oB-X:hover {
    background: #f3f3f5; }
    .widget_link__1oB-X:hover .widget_popover__1pHI1 {
      display: block; }

.widget_popover__1pHI1 {
  display: none;
  position: absolute;
  background: white;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.13);
  width: 100%;
  font-size: 11px;
  padding: 3px;
  border-radius: 3px;
  left: 0;
  bottom: -20px; }

.widget_form__rB8T4 {
  max-width: 420px; }

.widget_section-title__3R_-C {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-top: 50px;
  margin-bottom: -10px;
  display: flex; }

.widget_section-text__2fYxz {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #999;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex; }

.widget_row2__2E7Ci {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px; }

.widget_row3__3-Rzf {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  position: relative; }

.widget_remove__1fM_m {
  position: absolute;
  right: -150px;
  top: 58px; }

.widget_row-re__bvtWt {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 20px;
  align-items: center; }

.widget_re__bNM0D {
  margin-top: 48px; }

.widget_row-right__22v_7 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 10px; }

.widget_row-flex__1Kkof {
  display: flex;
  margin: 36px 0;
  align-items: center; }

.widget_icon-select__1GMiy {
  width: 40px;
  height: 40px;
  border: 1px solid #d3d3d5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  border-radius: 3px;
  margin-right: 20px;
  cursor: pointer; }
  .widget_icon-select__1GMiy:hover {
    opacity: 0.4; }

.widget_icon-selected__3_sio {
  color: black;
  border: 2px solid #000; }

.widget_upload__Kv00U {
  height: 44px;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  padding: 0 10px; }
  .widget_upload__Kv00U svg {
    position: absolute;
    right: 5px; }

.widget_upload-info__1mJwC {
  position: absolute;
  bottom: -45px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  opacity: 0.8; }

.widget_code__1lMpn {
  background: #f3f3f5;
  border: 1px solid #cdd1dc;
  color: #000;
  padding: 3px;
  font-weight: 500;
  font-family: 'Inconsolata';
  border-radius: 3px;
  display: block;
  padding: 5px 15px;
  width: 100%;
  position: relative; }
  .widget_code__1lMpn pre {
    font-size: 14px;
    font-family: 'Inconsolata';
    white-space: pre-wrap;
    word-break: break-word;
    color: #546291; }
    .widget_code__1lMpn pre b {
      font-weight: 600;
      color: #1e2a5c; }

.widget_buttons__jcTqL {
  margin-top: 15px; }
  .widget_buttons__jcTqL button {
    margin-right: 20px; }

.widget_higher__1wto- {
  display: block;
  position: relative; }

.widget_box__2s4E5 {
  position: absolute;
  top: -26px;
  background: white;
  padding: 15px 20px;
  border: 1px solid #e3e3e5;
  border-radius: 3px;
  z-index: 9;
  min-width: 450px; }
  .widget_box__2s4E5 .widget_tag__1KZBd {
    background: #d3fff5;
    color: #0e9e7f;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 3px; }
  .widget_box__2s4E5 .widget_heading__3vdiH {
    font-weight: 600;
    margin: 10px 0;
    font-size: 16px; }
  .widget_box__2s4E5 .widget_text__VMQxj {
    color: #333;
    opacity: 0.6;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .widget_box__2s4E5 .widget_list__2kXyC {
    padding: 0px 0;
    list-style: none; }
    .widget_box__2s4E5 .widget_list__2kXyC li {
      display: flex;
      align-items: center;
      margin: 10px 0;
      font-size: 14px;
      font-weight: 500;
      color: #333; }
    .widget_box__2s4E5 .widget_list__2kXyC svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: #19cda6; }

.title____1YSAH {
  font-size: 26px;
  font-weight: 600;
  margin-top: 60px; }

.input_wrapper__3UgSE {
  margin: 36px 0 0 0;
  position: relative;
  padding-top: 14px; }

.input_label__2tmVe {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0; }

.input_input__Q80XR {
  margin-top: 6px;
  height: 38px;
  width: 100%;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: 100ms linear;
  margin-bottom: 6px;
  color: #333;
  font-weight: 500; }
  .input_input__Q80XR::-webkit-input-placeholder {
    color: #ccc;
    font-weight: 400; }
  .input_input__Q80XR:-ms-input-placeholder {
    color: #ccc;
    font-weight: 400; }
  .input_input__Q80XR::-ms-input-placeholder {
    color: #ccc;
    font-weight: 400; }
  .input_input__Q80XR::placeholder {
    color: #ccc;
    font-weight: 400; }
  .input_input__Q80XR:focus {
    border: 2px solid #333;
    padding: 0 9px; }
    .input_input__Q80XR:focus + .input_label__2tmVe {
      color: #333;
      font-weight: 600; }

.input_no-margin__1rbr2 {
  margin: 0; }
  .input_no-margin__1rbr2 .input_input__Q80XR {
    margin: 6px 0 0 0; }

.input_input-error__2TXqV {
  border-bottom: 1px solid #f20c63; }
  .input_input-error__2TXqV:focus {
    border: 1px solid #d3d3d5;
    border-bottom: 2px solid #f20c63; }

.input_error__UFo6- {
  position: absolute;
  color: #f20c63;
  font-size: 12px;
  left: 3px;
  top: 65px;
  font-weight: 500; }

.checkbox_wrapper__2oN_7 {
  margin: 36px 0 18px 0;
  display: flex;
  align-items: center;
  cursor: pointer; }

.checkbox_no-margin__1AGWN {
  margin: 0; }

.checkbox_checkbox-input__3V1lN {
  display: none; }

.checkbox_checkbox__2wmYF {
  width: 18px;
  height: 18px;
  border: 1px solid #a3a3a5;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms linear;
  flex-shrink: 0; }
  .checkbox_checkbox__2wmYF svg {
    color: black; }

.checkbox_checkbox-input__3V1lN:checked + .checkbox_checkbox__2wmYF {
  border: 1px solid #333;
  background: #333; }
  .checkbox_checkbox-input__3V1lN:checked + .checkbox_checkbox__2wmYF svg {
    color: white; }

.checkbox_label__1B3ER {
  padding-left: 10px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px; }

.address_map__2bEph {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-position: center center;
  cursor: pointer;
  transition: 200ms ease-in-out;
  padding: 20px 30px; }
  .address_map__2bEph svg {
    margin-bottom: 16px;
    color: black; }

.launcher____5aBdb {
  bottom: 0;
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2147483647;
  transition: 200ms ease-in-out all;
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  outline: none;
  transition: 200ms ease-in-out all;
  cursor: pointer; }
  .launcher____5aBdb svg {
    transition: 200ms ease-in-out all;
    -webkit-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 32px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 32px rgba(0, 0, 0, 0.16)); }
  .launcher____5aBdb:hover {
    transform: translateY(-2px);
    opacity: 0.9; }
    .launcher____5aBdb:hover svg {
      -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.26));
              filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.26)); }
    .launcher____5aBdb:hover:active {
      transform: translateY(1px);
      opacity: 1; }
      .launcher____5aBdb:hover:active svg {
        -webkit-filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 32px rgba(0, 0, 0, 0.16));
                filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 32px rgba(0, 0, 0, 0.16)); }

.widget____3-aDf {
  position: absolute;
  transition: 200ms ease-in-out all; }

@-webkit-keyframes personal-proof_in__1K9QA {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes personal-proof_in__1K9QA {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.personal-proof____3AIFe {
  width: 320px;
  margin-bottom: 80px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  transition: 200ms ease-in-out;
  position: relative;
  -webkit-animation: personal-proof_in__1K9QA 500ms forwards;
          animation: personal-proof_in__1K9QA 500ms forwards;
  background: white;
  box-sizing: border-box; }

.personal-proof_wrapper__22m8X:hover .personal-proof_close__2wIXv {
  opacity: 1; }

.personal-proof_close__2wIXv {
  display: flex;
  opacity: 0;
  margin-left: auto;
  margin-bottom: 10px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  transition: 400ms ease-in-out;
  z-index: 2147483647;
  box-sizing: border-box; }

.personal-proof_head__a0wvK {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  box-sizing: border-box; }

.personal-proof_inner__1U0Qt {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.22));
  box-sizing: border-box; }

.personal-proof_operator__bA1PR {
  margin: 0;
  margin-left: 15px;
  box-sizing: border-box;
  font-family: 'Montserrat' !important; }

.personal-proof_name__g5sfJ {
  color: white;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin: 0 !important;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
  padding: 0 !important;
  line-height: 21px !important; }

.personal-proof_more__1wuvK {
  color: white;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin: 0 !important;
  margin-top: 8px !important;
  -webkit-margin-before: 0px !important;
          margin-block-start: 0px !important;
  opacity: 0.9;
  box-sizing: border-box !important;
  font-family: 'Montserrat' !important;
  padding: 0 !important;
  line-height: 21px !important; }

.personal-proof_content__3-ojT {
  padding: 20px 25px;
  box-sizing: border-box; }

.personal-proof_header__Z6t8F {
  font-size: 18px !important;
  color: #000 !important;
  margin: 0 !important;
  font-weight: 500;
  box-sizing: border-box;
  font-family: 'Montserrat' !important; }

.personal-proof_text__pQfTM {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400 !important;
  color: #666 !important;
  margin-top: 10px;
  margin-bottom: 0;
  box-sizing: border-box;
  font-family: 'Montserrat' !important;
  padding: 0; }

.personal-proof_avatar__2s-E0 {
  width: 64px;
  height: 64px;
  background: #e3e3e5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
  background-position: center center;
  background-size: cover;
  box-sizing: border-box; }

@media only screen and (max-width: 400px) {
  .personal-proof____3AIFe {
    width: 90%;
    margin: 30px 5% 60px 5%;
    border-radius: 12px; }
  .personal-proof_close__2wIXv {
    opacity: 1 !important; } }

@media only screen and (max-width: 800px) {
  .personal-proof_close__2wIXv {
    opacity: 1 !important; } }

.social-proof____3Dnqf {
  width: 375px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  overflow: hidden;
  transition: 200ms ease-in-out;
  display: flex;
  align-items: center;
  padding: 15px;
  flex-shrink: 0;
  position: relative;
  margin: 30px 0; }

.social-proof_image__4TjcT {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 15px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-position: center center;
  background-size: cover;
  border: 2px solid white;
  box-shadow: 0 0 0 1px black;
  overflow: hidden; }

.social-proof_inner__35VBb {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.12)); }

.social-proof_top__G2sRK {
  background: #f3f3f5;
  margin: 0;
  font-size: 14px;
  padding: 1px 4px;
  font-weight: 500;
  color: #000; }

.social-proof_text__12arA {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  margin-top: 3px;
  margin-left: 4px;
  color: #333; }

.social-proof_bottom__3N-na {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-left: 4px; }

.social-proof_time__1u7BY {
  margin: 0;
  font-size: 11px;
  padding: 1px 0px;
  font-weight: 500;
  color: #999; }

.social-proof_credit__3NWDY {
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #000;
  transition: 200ms ease-in-out all;
  cursor: pointer; }
  .social-proof_credit__3NWDY:hover {
    opacity: 0.5; }

.social-proof_check__2CO4_ {
  background: #000;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px; }

.color-input_wrapper__1Csnq {
  display: flex;
  align-items: center;
  margin: 36px 0 0 0;
  position: relative; }

.color-input_input__3RIVP {
  padding-left: 46px; }
  .color-input_input__3RIVP:focus {
    padding: 0 45px; }

.color-input_color__1NaHf {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%; }

.color-input_handle__2O473 {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #777;
  border-radius: 100%;
  margin-left: 13px; }

.color-input_picker__21kmF {
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 999999; }

.select_wrapper__3G2oJ {
  margin: 36px 0 0 0;
  position: relative;
  padding-top: 14px; }

.select_label__3CMj1 {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0; }

.select_select__XlnEk {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  background: white;
  margin-top: 6px;
  height: 38px;
  width: 100%;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  appearance: none;
  transition: 100ms linear;
  margin-bottom: 16px;
  color: #333; }
  .select_select__XlnEk:focus {
    border: 2px solid #333;
    padding: 0 9px; }

.select_chevron__217G1 {
  position: absolute;
  width: 20px;
  top: 28px;
  right: 12px;
  color: #c3c3c5;
  pointer-events: none; }

.select_no-margin__3QQFr {
  margin: 0; }

.select_no-label__WlNKc .select_chevron__217G1 {
  top: 15px; }

.select_size-small__oS4mb {
  height: 26px;
  font-size: 12px;
  font-weight: 500;
  margin-top: -20px;
  margin-left: 20px;
  min-width: 100px; }
  .select_size-small__oS4mb .select_select__XlnEk {
    height: 26px;
    font-size: 12px !important;
    font-weight: 500;
    margin-top: 3px; }
  .select_size-small__oS4mb .select_chevron__217G1 {
    top: 18px;
    width: 16px;
    right: 8px; }

.select_label-small__2Eg7K {
  font-size: 14px; }

.select_select-error__5SjSk {
  border-color: #f20c63; }
  .select_select-error__5SjSk:focus {
    border-color: #f20c63; }

.select_error__3Ee7O {
  position: absolute;
  color: #f20c63;
  font-size: 12px;
  left: 3px;
  bottom: 0px;
  font-weight: 500; }

.textarea_label__1htCK {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  position: absolute;
  top: 0; }

.textarea_textarea__2AOQQ {
  margin-top: 6px;
  width: 100%;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 8px 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: 100ms linear;
  margin-bottom: 6px;
  color: #333;
  font-weight: 500;
  height: 75px; }
  .textarea_textarea__2AOQQ::-webkit-input-placeholder {
    color: #ccc;
    font-weight: 400; }
  .textarea_textarea__2AOQQ:-ms-input-placeholder {
    color: #ccc;
    font-weight: 400; }
  .textarea_textarea__2AOQQ::-ms-input-placeholder {
    color: #ccc;
    font-weight: 400; }
  .textarea_textarea__2AOQQ::placeholder {
    color: #ccc;
    font-weight: 400; }
  .textarea_textarea__2AOQQ:focus {
    border: 2px solid #333;
    padding: 8px 9px; }
    .textarea_textarea__2AOQQ:focus + .textarea_label__1htCK {
      color: #333;
      font-weight: 600; }

.queue_wrapper__3hvWQ {
  margin: 20px 0;
  padding: grid; }

.queue_cell__1-wH3 {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e3e3e5;
  background: white;
  position: relative; }
  .queue_cell__1-wH3:first-of-type {
    border-top: 1px solid #e3e3e5; }

.queue_dragging__OY540 {
  border-top: 1px solid #e3e3e5; }

.queue_index__2oiEX {
  background: #333;
  width: 24px;
  height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100%;
  margin-right: 15px;
  margin-left: 15px; }

.queue_user__fHCkx {
  display: flex;
  align-items: center; }

.queue_userc__3mbDV {
  margin-left: 20px; }

.queue_name__2p3YN {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-bottom: 2px; }

.queue_email__1QFeh {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin: 0;
  margin-bottom: 4px; }

.phone-input_wrapper__2gef- {
  display: flex;
  align-items: center;
  margin: 36px 0 0 0; }
  .phone-input_wrapper__2gef- button {
    width: 145px;
    margin-left: 4px;
    height: 38px;
    margin-top: 14px; }

.phone-input_phone__3btP- {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%; }

.phone-input_input__3TADk {
  padding-left: 76px; }
  .phone-input_input__3TADk:focus {
    padding: 0 75px; }

.phone-input_phone-code__2twl8 {
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 10px;
  pointer-events: none;
  font-size: 14px; }
  .phone-input_phone-code__2twl8 img {
    margin-right: 8px; }

.phone-input_info__cb295 {
  position: absolute;
  color: #999;
  font-size: 10px;
  left: 3px;
  top: 65px;
  font-weight: 500; }

.dashboard_header__2gN8S {
  display: flex;
  margin-bottom: 30px; }

.dashboard_dates__quUHL {
  margin-top: 36px;
  margin-left: 30px;
  width: 220px; }
  .dashboard_dates__quUHL label {
    display: block;
    font-weight: 500;
    padding-left: 3px;
    font-size: 12px;
    color: #666; }

.dashboard_grid__2tbUc {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px; }

.summary_head__17wWv {
  display: flex;
  align-items: center; }

.summary_card__vFMU- {
  padding: 10px 30px;
  position: relative;
  cursor: pointer;
  min-width: 230px;
  width: 33.333%;
  transition: 200ms ease-in-out;
  border-radius: 3px;
  border: 1px solid #fff; }
  .summary_card__vFMU-:hover {
    background: #f3f3f5; }

.summary_card-selected__36GOi {
  border: 1px solid #0066ff;
  background: rgba(0, 111, 225, 0.12); }
  .summary_card-selected__36GOi:after {
    width: 100%;
    height: 2px;
    background: black;
    position: absolute;
    bottom: -1px;
    left: 0; }
  .summary_card-selected__36GOi:hover {
    background: rgba(0, 111, 225, 0.12); }
  .summary_card-selected__36GOi .summary_card-label__2HM3O {
    color: #3f424e;
    font-weight: 500; }
  .summary_card-selected__36GOi .summary_card-value__1tJRd {
    font-weight: 700;
    color: #1c1f2b; }

.summary_card-label__2HM3O {
  font-size: 13px;
  font-weight: 500;
  color: #999999;
  margin: 5px 0; }

.summary_card-value__1tJRd {
  font-weight: 500;
  font-size: 26px;
  padding-left: 1px;
  margin-bottom: 10px; }

.summary_chart__26YUE {
  margin-top: 20px; }

.card____1gX5- {
  margin: 30px 0; }

.card_title__JD38p {
  font-weight: 600;
  font-size: 17px; }

.card_subtitle__AFmmQ {
  font-size: 14px;
  color: #999;
  font-weight: 500;
  margin-top: 5px; }

.card_content__30Ol7 {
  margin-top: 25px; }

.stat____llrpO {
  display: flex;
  align-items: center;
  margin: 20px 0; }

.stat_icon__2NvTL {
  margin-right: 20px;
  color: #518bba; }

.stat_label__2lz1h {
  font-size: 13px;
  font-weight: 500;
  color: #999; }

.stat_value__2n9QG {
  font-size: 24px;
  font-weight: 600; }

.table____1dinb {
  width: 100%; }
  .table____1dinb th {
    color: #666;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 20px; }
  .table____1dinb td {
    border-top: 1px solid #e3e3e5;
    padding: 10px 0;
    font-size: 13px;
    color: #333;
    font-weight: 500; }

.signup____8eUsM {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 100px 0; }

.signup_title__2AghN {
  font-size: 26px;
  font-weight: 500;
  color: #000;
  line-height: 32px;
  margin-top: 40px;
  margin-top: 60px; }

.signup_form__wAOR6 {
  max-width: 420px; }

.signup_left__1hTB1 {
  border-right: 1px solid #f3f3f5;
  margin-right: 30px; }

.signup_right__3UsdQ {
  padding-top: 80px; }

.signup_subtitle__3FPEn {
  font-weight: 600;
  color: #000;
  line-height: 2.2rem;
  margin-bottom: 0; }

.signup_text__1V6en {
  font-size: 15px;
  color: #aaa;
  font-weight: 500;
  margin-bottom: 34px;
  margin-top: 5px; }

.signup_list__1HdZ5 {
  list-style: none;
  padding: 5px 0 200px 0; }
  .signup_list__1HdZ5 li {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 14px;
    font-weight: 500;
    color: #333; }
    .signup_list__1HdZ5 li svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: #19cda6; }

.signup_logos__1KDOT {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center; }
  .signup_logos__1KDOT img {
    margin-right: 30px; }

.signup_terms__1AbWb {
  font-size: 12px; }
  .signup_terms__1AbWb a {
    font-size: 12px;
    color: black;
    text-decoration: underline; }

.signup_link__22YIE {
  color: #666;
  margin-top: 13px;
  font-size: 12px;
  font-weight: 500; }
  .signup_link__22YIE:hover {
    color: #000; }

.signup_article__3bxxP {
  display: flex;
  align-items: center;
  margin-top: 35px;
  border-radius: 6px;
  overflow: hidden;
  transition: 200ms all linear;
  flex-shrink: 0; }
  .signup_article__3bxxP:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1); }

.signup_article-image__3lN9d {
  width: 130px;
  height: 130px; }
  .signup_article-image__3lN9d img {
    height: 100%;
    width: 130px;
    object-fit: cover;
    object-position: center; }

.signup_article-content__2c9Eg {
  background: #f3fcfa;
  padding: 15px; }
  .signup_article-content__2c9Eg span {
    color: #19cda6;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px; }
    .signup_article-content__2c9Eg span svg {
      margin-right: 6px; }
  .signup_article-content__2c9Eg h3 {
    margin: 3px 0;
    font-weight: 700;
    color: #000; }
  .signup_article-content__2c9Eg p {
    margin: 10px 0 0 0;
    font-size: 13px;
    color: #333;
    opacity: 0.9; }

@media screen and (max-width: 600px) {
  .signup_rev__2B5Ay {
    flex-direction: column-reverse; }
  .signup_log__LDlrZ {
    position: absolute;
    top: 30px;
    left: 10px; }
  .signup_text__1V6en {
    font-size: 14px; }
  .signup_title__2AghN {
    font-size: 21px; }
  .signup_article-image__3lN9d {
    display: none; }
  .signup_list__1HdZ5 {
    padding: 30px 0; }
    .signup_list__1HdZ5 li {
      font-size: 12px; }
  .signup_logos__1KDOT {
    position: relative;
    display: block;
    align-items: center; }
    .signup_logos__1KDOT img {
      display: block;
      margin: 30px auto; }
  .signup_left__1hTB1 {
    border: none; } }

.code-input_wrapper__3nQkU {
  margin-top: 48px; }

.code-input_input__2CCwg {
  margin-right: 6px;
  margin-top: 6px;
  width: 38px; }

.settings_form__h69fg {
  max-width: 400px; }

.settings_label__2d6VG {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #666; }

.settings_avatar__tjvK- {
  margin: 20px 0 10px 0; }

.settings_info__3YbKB {
  color: #888;
  font-size: 12px;
  line-height: 18px;
  left: 3px;
  top: 65px;
  font-weight: 500;
  display: block;
  max-width: 400px;
  margin-top: 7px; }

.list_header__2_xHP {
  display: flex;
  align-items: center; }
  .list_header__2_xHP .list_button__3UASU {
    margin-left: 20px;
    margin-top: 40px; }

.list_table__QNBOW {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse; }
  .list_table__QNBOW th {
    text-align: left;
    padding-bottom: 10px;
    color: #777;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    padding: 10px 20px; }
  .list_table__QNBOW td {
    font-size: 13px;
    color: #333;
    font-weight: 600;
    border-top: 1px solid #f3f3f5;
    padding: 20px 20px; }
    .list_table__QNBOW td small {
      font-weight: 500;
      opacity: 0.7; }

.list_select__3eayP {
  margin-left: -20px;
  max-width: 150px; }

.list_user__2tpAr {
  display: flex;
  align-items: center; }

.list_userc__2pGVB {
  margin-left: 20px; }

.list_name__foJqv {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
  margin-bottom: 2px; }

.list_email__3VCda {
  font-size: 12px;
  font-weight: 500;
  color: #999;
  margin: 0;
  margin-bottom: 4px; }

.list_tag__1nEtk {
  background: #333;
  color: white;
  padding: 3px 10px;
  font-size: 11px;
  border-radius: 3px;
  margin-left: 12px;
  font-weight: 600; }

.modal_overlay__3GC-N {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; }

.modal_wrapper__1xWji {
  background: white;
  width: 540px;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
  margin-top: -150px; }

.modal_header__2hP-3 {
  font-size: 19px;
  color: #333;
  font-weight: 500;
  padding: 20px 25px;
  display: flex;
  align-items: center; }

.modal_close__1xfsJ {
  position: absolute;
  right: 25px;
  cursor: pointer;
  opacity: 0.5; }

.modal_body__2bgsT {
  padding: 5px 25px 30px 25px; }
  .modal_body__2bgsT p {
    font-size: 14px;
    font-weight: 500;
    color: #333; }

.invite_form__1IUrl {
  max-width: 380px; }

.invite_label__1sLaM {
  display: block;
  font-weight: 500;
  padding-left: 3px;
  font-size: 12px;
  color: #666;
  margin-top: 30px; }

.invite_roles__jvD0p {
  display: grid;
  grid-template-columns: 180px 180px 180px;
  grid-gap: 20px;
  margin-top: 6px; }

.invite_role__2EgZV {
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  padding: 20px;
  position: relative;
  cursor: pointer; }

.invite_role-selected__1Pwed {
  border: 1px solid #333; }

.invite_role-check__RQ5bM {
  position: absolute;
  background: #444;
  width: 23px;
  height: 23px;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 15px; }

.invite_role-name__S_buk {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
  margin-bottom: 30px; }

.invite_role-feature__1DDuq {
  margin: 10px 0;
  font-size: 12px;
  font-weight: 500;
  color: #333; }

.list____2lnfk {
  display: block;
  border-right: 1px solid #e3e3e5;
  max-height: calc(100vh - 177px);
  width: 340px;
  overflow-y: scroll; }

.list_message__1Ajog {
  margin: 0px 0;
  margin-right: 40px;
  color: #000;
  padding: 20px;
  border-bottom: 1px solid #e3e3e5;
  min-height: 100px;
  cursor: pointer;
  display: block;
  border-left: 2px solid #fff;
  transition: all 100ms ease-in-out; }
  .list_message__1Ajog:hover {
    opacity: 0.6; }

.list_message-seen__25Gj_ .list_message-lead__1QhSA {
  color: #888;
  font-weight: 500; }

.list_message-selected__201Go {
  border-left: 2px solid #000;
  transition: all 100ms ease-in-out; }
  .list_message-selected__201Go .list_message-lead__1QhSA {
    color: #000 !important; }
  .list_message-selected__201Go:hover {
    opacity: 1; }
  .list_message-selected__201Go .list_message-content__2AlC6 {
    color: #333;
    font-weight: 500; }

.list_message-top__1FSF9 {
  display: flex;
  align-items: center;
  margin-bottom: 6px; }

.list_message-lead__1QhSA {
  font-size: 14px;
  justify-self: flex-start;
  font-weight: 600; }

.list_message-data__1I9MT {
  font-size: 11px;
  justify-self: flex-end;
  margin-left: auto;
  text-align: right;
  font-weight: 600;
  color: #bbb; }

.list_message-content__2AlC6 {
  font-size: 13px;
  opacity: 0.9;
  color: #999;
  font-weight: 400; }

.list_empty__3VXWd {
  width: 200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center; }
  .list_empty__3VXWd svg {
    color: #d3d3d5;
    margin-bottom: 10px; }
  .list_empty__3VXWd span {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    opacity: 0.5; }

.single____2HeLk {
  padding-left: 30px; }

.single_header__3-CO8 {
  height: 180px;
  position: relative;
  background-position: center center;
  background-size: cover; }

.single_header-inner__1M5wV {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.6) 100%);
  padding: 30px; }

.single_lead__3snhK {
  font-size: 21px;
  font-weight: 600;
  margin: 30px 0; }

.single__stats__3rEi5 {
  display: flex;
  align-content: center;
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  flex-wrap: wrap; }

.single_stats__1WlcU {
  display: flex;
  align-content: center;
  margin-right: 10px;
  margin-top: 10px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.12); }
  .single_stats__1WlcU svg {
    margin: 10px;
    color: #333; }

.single_stats-icon__3l3EO {
  display: flex;
  align-content: center;
  height: 100%;
  margin-right: 10px; }

.single_stats-label__cRP6b {
  font-size: 10px;
  font-weight: 600;
  color: #666;
  margin-top: 5px; }

.single_stats-value__2sLlr {
  font-size: 14px;
  font-weight: 600;
  color: #333; }

.single_table__2-Gts {
  width: 100%;
  margin: 20px 0; }

.single_label__HEPR2 {
  width: 180px;
  text-transform: uppercase;
  color: #999;
  font-size: 12px;
  padding: 10px;
  vertical-align: top; }

.single_value__2w6CS {
  font-size: 15px;
  color: #333;
  padding: 10px;
  line-height: 24px; }

.messages_grid__Tkehx {
  display: grid;
  grid-template-columns: 340px 1fr; }

.messages_header__aN06O {
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px; }
  .messages_header__aN06O .messages_div__1jkcF {
    width: 30px; }

.messages_dates__16kaU {
  margin-top: 36px;
  margin-right: 30px;
  margin-left: 30px;
  width: 220px; }
  .messages_dates__16kaU label {
    display: block;
    font-weight: 500;
    padding-left: 3px;
    font-size: 12px;
    color: #666; }

.list_header__1_g5u {
  display: flex;
  margin-top: 30px; }
  .list_header__1_g5u .list_div__XHRlF {
    width: 30px; }

.list_dates__1DIYW {
  margin-top: 36px;
  margin-right: 30px;
  margin-left: 30px;
  width: 220px; }
  .list_dates__1DIYW label {
    display: block;
    font-weight: 500;
    padding-left: 3px;
    font-size: 12px;
    color: #666; }

.list_table__3dNPt {
  width: 100%;
  margin-top: 50px; }
  .list_table__3dNPt th {
    padding: 10px 0;
    font-size: 12px;
    color: #333;
    opacity: 0.5;
    font-weight: 600; }
  .list_table__3dNPt td {
    padding: 20px 0;
    border-top: 1px solid #f3f3f5;
    font-size: 13px;
    font-weight: 500;
    color: #333; }

.list_status__1UQwJ {
  display: flex; }

.list_status-icon__399dR {
  margin-right: 15px; }

.list_type-type__xpEVI {
  font-size: 13px; }

.list_type-text__33aJ2 {
  opacity: 0.5;
  font-size: 12px;
  margin-top: 3px; }

.list_operator__1jW8g {
  display: flex;
  align-items: center; }

.list_operator-data__1KYJW {
  margin-left: 15px; }

.list_operator-name__1XUuG {
  font-size: 13px; }

.list_operator-phone__3bgEo {
  font-size: 13px;
  opacity: 0.5;
  font-size: 12px;
  margin-top: 3px; }

.list_empty__2oyYw {
  width: 200px;
  margin: 50px auto;
  padding: 20px;
  text-align: center; }
  .list_empty__2oyYw svg {
    color: #d3d3d5;
    margin-bottom: 10px; }
  .list_empty__2oyYw span {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    opacity: 0.5; }

.single_grid__1WGUW {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px; }

.single_map__1Fo_W {
  height: 150px;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  margin-top: 30px;
  border-radius: 3px; }

.single_dot__1JZtV {
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 100%;
  background: #006fff;
  border-width: 3px;
  border-style: solid;
  border-color: #5ba0f9;
  box-shadow: 0 0 0 2px #bdd7fa; }

.single_city__IP_e0 {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 11px;
  font-weight: 500;
  box-shadow: rgba(0, 111, 255, 0.3) 0px 1px 3px;
  background: white;
  padding: 1px 7px;
  border-radius: 3px; }

.single_map-inner__1n0jC {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 20px;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.4) 20%, rgba(255, 255, 255, 0) 100%); }

.single_title__1YT0w {
  font-size: 24px;
  font-weight: 600; }

.single_number__TJWyF {
  font-size: 18px;
  font-weight: 600;
  margin-top: 27px;
  margin-bottom: 11px; }
  .single_number__TJWyF span {
    font-size: 13px;
    opacity: 0.9;
    font-weight: 500; }

.single_actions__3VX1f button {
  margin-right: 20px; }

.single_stat__1_tvb {
  display: flex;
  align-items: center;
  margin: 20px 0; }

.single_icon__1mAfn {
  margin-right: 20px;
  color: #0066ff; }

.single_label__zsZbG {
  font-size: 12px;
  font-weight: 600;
  color: #637098; }

.single_value__38Nvc {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9; }

.single_subtitle__3FsCL {
  font-weight: 600;
  margin: 0;
  font-size: 18px; }

.single_recording__3uzph {
  max-width: 600px;
  margin-bottom: 50px; }

.single_blocked__K8oj- {
  background: #ff006f;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  border-radius: 4px; }

.billing_links__1Wuj6 {
  margin: 40px 0; }

.billing_link__2lWv4 {
  padding: 10px 25px 10px 10px;
  border-bottom: 2px solid #e3e3e5;
  color: #999;
  font-weight: 600;
  transition: 200ms ease-in-out; }
  .billing_link__2lWv4 svg {
    margin-right: 6px; }

.billing_active__m9Eir {
  border-color: #000;
  border-width: 3px;
  color: #333; }

.stats_row__3P5Ba {
  display: flex;
  margin: 40px 0; }

.stats_info__THBNb {
  margin-right: 100px; }

.stats_labels__2FNWp {
  display: flex;
  align-items: center; }

.stats_right__1eSH8 {
  margin-left: auto;
  text-align: right; }

.stats_label__3QmIV {
  color: #333;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.7; }

.stats_value__255SD {
  font-size: 28px;
  font-weight: 600;
  margin-top: 3px;
  margin-bottom: 15px; }

.stats_bar__2u-sb {
  width: 300px;
  height: 16px;
  border-radius: 3px;
  background: #e3e3e5;
  margin: 10px 0 0 0;
  overflow: hidden; }

.stats_progress__xjheJ {
  height: 100%;
  background: #333; }

.stats_small__28Kh3 {
  display: block;
  font-size: 12px;
  color: #333;
  opacity: 0.6;
  padding-top: 6px;
  font-weight: 500; }

.stats_card__1B8Wg {
  display: inline-block;
  border: 1px solid #e3e3e5;
  margin: 30px 0;
  border-radius: 3px;
  padding: 10px 60px 30px 60px;
  text-align: center; }

.stats_title__3DM1b {
  font-size: 21px;
  font-weight: 600;
  margin: 20px 0;
  color: #333; }

.data_title__2TrKL {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-top: 50px;
  margin-bottom: -10px; }

.data_form__372bW {
  width: 500px; }

.data_row3__2cTdT {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px; }

.data_row2__vWKoe {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px; }

.data_card-input__2rsOn {
  height: 38px;
  border: 1px solid #e3e3e5;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 0 15px; }

.data_card-label__1MpRp {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  opacity: 0.9;
  padding-left: 2px;
  margin: 0px 0 8px 0; }

.data_submit__3OYM0 {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #f3f3f5; }

.data_error__2I4Qd {
  color: #f20c63;
  font-size: 12px;
  padding: 10px 0; }

.data_cards__3JNR1 {
  margin: 30px 0 0 0;
  width: 800px; }
  .data_cards__3JNR1 td {
    padding: 10px 0; }

.data_card__2oNFP {
  display: flex;
  align-items: center;
  color: #666;
  font-weight: 500;
  font-size: 14px; }
  .data_card__2oNFP img {
    margin-right: 10px; }
  .data_card__2oNFP small {
    font-weight: 600;
    color: #000;
    padding-left: 6px; }

.data_expiration__1Z295 {
  font-size: 14px;
  font-weight: 500;
  color: #666; }

.packages_trial__1AZ_A {
  text-align: center;
  width: 600px; }

.packages_trial-title__JocJX {
  font-weight: 600;
  font-size: 21px; }

.packages____3ILq5 {
  width: 400px; }

.packages_title__30ZC_ {
  font-weight: 600;
  font-weight: 14px; }

.packages_info__1aqBC {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  opacity: 0.5; }

.invoices_trial__1a3a4 {
  text-align: center;
  width: 600px; }

.invoices_trial-title__2EHPi {
  font-weight: 600;
  font-size: 21px; }

.invoices_title__22G3V {
  font-weight: 600;
  font-weight: 14px; }

.invoices_table__2fJLk {
  width: 100%;
  margin-top: 30px; }
  .invoices_table__2fJLk td,
  .invoices_table__2fJLk th {
    padding: 10px 5px; }
  .invoices_table__2fJLk td {
    font-size: 14px;
    font-weight: 500;
    color: #333; }
  .invoices_table__2fJLk th {
    font-weight: 500;
    color: #333;
    opacity: 0.5;
    font-size: 14px; }

.subscription_trial__1yhbC {
  text-align: center;
  width: 600px; }

.subscription_trial-title__6MzfB {
  font-weight: 600;
  font-size: 21px; }

.subscription____22S1i {
  width: 400px; }

.subscription_title__1NYQj {
  font-weight: 600;
  font-weight: 14px; }

.subscription_info__11mtJ {
  display: block;
  margin-top: 5px;
  font-weight: 500;
  opacity: 0.5; }

.subscription_text__18QGK {
  font-size: 13px;
  color: #999;
  font-weight: 500;
  line-height: 18px;
  opacity: 1; }
  .subscription_text__18QGK a {
    font-size: 13px; }

.start____2Tr1H {
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px 0; }

.start_header__NFT2J {
  text-align: center;
  margin: 0px 0; }

.start_title__123ym {
  font-weight: 500;
  font-size: 28px; }

.plans____O-DIQ {
  width: 660px;
  margin: 0 auto;
  padding: 20px 0; }

.plans__title__3gjAH {
  font-weight: 500; }

.plans_yearly__3K-n6 {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 0; }
  .plans_yearly__3K-n6 i {
    font-size: 12px;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    margin-right: 10px;
    display: block; }

.plans_plans__3aMK- {
  display: inline-flex;
  align-items: center;
  margin: 50px 0 0 0;
  border-radius: 3px; }

.plans_plan__1ScUP {
  width: 220px;
  border: 1px solid #fff;
  border-top: 1px solid #e3e3e5;
  border-bottom: 1px solid #e3e3e5;
  border-right: 1px solid #e3e3e5;
  padding: 20px 20px;
  position: relative;
  cursor: pointer; }
  .plans_plan__1ScUP:first-of-type {
    border-left: 1px solid #e3e3e5; }
  .plans_plan__1ScUP:hover {
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid #999; }

.plans_selected__3liH3 {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid #333;
  border-left: 1px solid #333; }
  .plans_selected__3liH3:first-of-type {
    border-left: 1px solid #333; }
  .plans_selected__3liH3:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid #333;
    border-left: 1px solid #333; }

.plans_popular__UHoLj {
  position: absolute;
  height: 24px;
  top: -25px;
  left: 0;
  background: #333;
  color: white;
  font-size: 12px;
  width: 219px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-weight: 600;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.plans_name__3vPkN {
  font-size: 18px;
  font-weight: 500;
  margin: 0; }
  .plans_name__3vPkN b {
    font-weight: 600; }

.plans_desc__aKZWN {
  opacity: 0.7;
  font-size: 13px;
  margin: 5px 0; }

.plans_prices__3kyIC {
  text-align: center;
  margin: 50px 0 40px 0; }

.plans_pricel__fG6h5 {
  display: block;
  margin-bottom: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #333; }

.plans_pricem__2e3cA {
  display: block;
  text-align: center;
  font-size: 13px;
  padding-top: 20px;
  font-weight: 500;
  color: #333; }

.plans_price__HqIJt {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0; }
  .plans_price__HqIJt small {
    font-size: 16px; }
  .plans_price__HqIJt span {
    font-size: 18px;
    font-weight: 400;
    padding-left: 5px; }

.plans_functions__3x7FT {
  display: inline-flex;
  align-items: center;
  margin: 50px 0; }

.plans_col__3RMSr {
  width: 220px; }

.plans_col-title__2Leqk {
  font-size: 15px;
  font-weight: 600; }

.plans_info__23ITk {
  padding-top: 0;
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 15px;
  color: #666;
  font-weight: 500; }
  .plans_info__23ITk b {
    color: #000;
    font-weight: 600; }

.address____FH79D {
  width: 660px;
  margin: 0 auto;
  padding: 20px 0; }

.address__title__38lpl {
  font-weight: 500; }

.address_row3__3VYaD {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px; }

.address_row2__3BEMP {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px; }

.card____2WLVz {
  width: 660px;
  margin: 0 auto;
  padding: 20px 0; }

.card__title__3gQ1E {
  font-weight: 500; }

.card_card-input__1FGF1 {
  height: 38px;
  border: 1px solid #e3e3e5;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 0 15px; }

.card_card-label__AdTfD {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  opacity: 0.9;
  padding-left: 2px;
  margin: 0px 0 8px 0; }

.card_submit__-GccA {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #f3f3f5; }

.card_error__3NMbD {
  color: #f20c63;
  font-size: 12px;
  padding: 10px 0; }

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Open Sans', 'Lato', sans-serif;
  /* background: #f7f9fc; */
  max-width: 100%;
  /* overflow-x: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: rgb(0, 111, 255);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

hr {
  border: none;
  height: 1px;
  background: #e3e3e5;
  margin: 30px 0;
}

.app {
  display: grid;
  grid-template-columns: 260px 1fr;
  padding-right: 40px;
}

.messages {
  position: fixed;
  top: 0px;
  left: 50%;
  z-index: 2000;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  background: #e3e3e5;
  inset: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  margin: 30px 0;
}

.center-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

table {
  border-spacing: 0px;
}

/* 
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table td {
  border-top: 1px solid #f3f3f5;
  border-bottom: 1px solid #f3f3f5;
  padding: 10px 0;
} */

.DateRangePicker {
  margin-top: 6px;
  display: block;
}

.DateRangePickerInput {
  /* display: flex;
  align-items:  */
  display: flex;
  align-items: center;
}

.DateRangePickerInput__withBorder {
  border-color: #e3e3e5;
}

.DateInput {
  width: 100%;
}

.DateInput_input {
  height: 36px;
  font-size: 14px;
  color: #333;
}
.DateInput_input__focused {
  border-color: #333;
}

.braintree-hosted-fields-focused {
  border: 2px solid #333333 !important;
  padding: 0 14px;
}
.braintree-hosted-fields-invalid {
  border: 2px solid #f20c63 !important;
  padding: 0 14px;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-tooltip-wrapper {
  border: 1px solid white;
  background: white;
  border-radius: 4px;
  box-shadow: 0 0 22px 1px rgba(0, 0, 0, 0.12);
}
.recharts-default-tooltip {
  border: 1px solid white !important;
  border-radius: 4px;
  padding: 7px 10px 0 10px !important;
}

.recharts-tooltip-label {
  font-size: 12px;
  font-weight: 500;
  color: #777;
}

.recharts-tooltip-item-list {
  margin-top: 10px;
}

.recharts-tooltip-item {
  font-size: 14px;
  font-weight: 600;
}

.recharts-legend-item-text {
  font-size: 13px;
  color: #666;
  font-weight: 500;
}


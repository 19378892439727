.wrapper {
  margin: 36px 0 18px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.no-margin {
  margin: 0;
}

.checkbox-input {
  display: none;
}

.checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid #a3a3a5;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms linear;
  flex-shrink: 0;

  svg {
    color: black;
  }
}

.checkbox-input:checked + .checkbox {
  border: 1px solid #333;
  background: #333;
  svg {
    color: white;
  }
}

.label {
  padding-left: 10px;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.header {
  display: flex;
  margin-bottom: 30px;
}

.dates {
  margin-top: 36px;
  margin-left: 30px;
  width: 220px;

  label {
    display: block;
    font-weight: 500;
    padding-left: 3px;
    font-size: 12px;
    color: #666;
  }
}

.grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
}

._ {
  display: block;
  border-right: 1px solid #e3e3e5;
  max-height: calc(100vh - 177px);
  width: 340px;
  overflow-y: scroll;
}

.message {
  margin: 0px 0;
  margin-right: 40px;
  color: #000;
  padding: 20px;
  border-bottom: 1px solid #e3e3e5;
  min-height: 100px;
  cursor: pointer;
  display: block;
  border-left: 2px solid #fff;
  transition: all 100ms ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

.message-seen {
  .message-lead {
    color: #888;
    font-weight: 500;
  }
}
.message-selected {
  border-left: 2px solid #000;
  transition: all 100ms ease-in-out;

  .message-lead {
    color: #000 !important;
  }

  &:hover {
    opacity: 1;
  }

  .message-content {
    color: #333;
    font-weight: 500;
  }
}

.message-top {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.message-lead {
  font-size: 14px;
  justify-self: flex-start;
  font-weight: 600;
}
.message-data {
  font-size: 11px;
  justify-self: flex-end;
  margin-left: auto;
  text-align: right;
  font-weight: 600;
  color: #bbb;
}
.message-content {
  font-size: 13px;
  opacity: 0.9;
  color: #999;
  font-weight: 400;
}

.empty {
  width: 200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  svg {
    color: #d3d3d5;
    margin-bottom: 10px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    opacity: 0.5;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin: 36px 0 0 0;

  button {
    width: 145px;
    margin-left: 4px;
    height: 38px;
    margin-top: 14px;
  }
}

.phone {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
}

.input {
  padding-left: 76px;
  &:focus {
    padding: 0 75px;
  }
}

.phone-code {
  font-weight: 500;
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 10px;
  pointer-events: none;
  font-size: 14px;

  img {
    margin-right: 8px;
    // box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.1);
  }
}

.info {
  position: absolute;
  color: #999;
  font-size: 10px;
  left: 3px;
  top: 65px;
  font-weight: 500;
}

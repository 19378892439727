.header {
  display: flex;
  align-items: center;

  .switch {
    margin-top: 44px;
    margin-left: 20px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 450px;
}

.preview {
  border-left: 1px solid #f3f3f5;
  border-bottom: 1px solid #f3f3f5;
  border-right: 1px solid #f3f3f5;
  height: 83vh;
  position: relative;
}

.link-row {
  display: flex;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.row-title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  border-bottom: 1px solid #e3e3e5;
  padding: 10px 0;
  margin-right: 40px;
  opacity: 0.5;
}

.link {
  display: block;
  text-align: center;
  margin: 20px 40px 20px 0;
  color: #333;
  // height: 60px;
  // border: 1px solid #d3d3d5;
  padding: 20px 10px;
  border-radius: 3px;
  transition: 100ms all linear;
  position: relative;

  svg {
    // margin-right: 20px;
    opacity: 0.9;
    margin-bottom: 10px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    opacity: 0.5;
  }

  &:hover {
    background: #f3f3f5;
    // opacity: 0.5;

    .popover {
      display: block;
    }
  }
}

.popover {
  display: none;
  position: absolute;
  background: white;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.13);
  width: 100%;
  font-size: 11px;
  padding: 3px;
  border-radius: 3px;
  left: 0;
  bottom: -20px;
}

.form {
  max-width: 420px;
}

.section-title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-top: 50px;
  margin-bottom: -10px;
  display: flex;
}

.section-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #999;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.row3 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 20px;
  position: relative;
}

.remove {
  position: absolute;
  right: -150px;
  top: 58px;
}

.row-re {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 20px;
  align-items: center;
}

.re {
  margin-top: 48px;
}

.row-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 10px;
}

.row-flex {
  display: flex;
  margin: 36px 0;
  align-items: center;
}

.icon-select {
  width: 40px;
  height: 40px;
  border: 1px solid #d3d3d5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  border-radius: 3px;
  margin-right: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }
}

.icon-selected {
  color: black;
  border: 2px solid #000;
}

.upload {
  height: 44px;
  border: 1px solid #d3d3d5;
  border-radius: 3px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  padding: 0 10px;

  svg {
    position: absolute;
    right: 5px;
  }
}

.upload-info {
  position: absolute;
  bottom: -45px;
  font-size: 12px;
  font-weight: 500;
  color: #333;
  opacity: 0.8;
}

.code {
  background: #f3f3f5;
  border: 1px solid #cdd1dc;
  color: #000;
  padding: 3px;
  font-weight: 500;
  font-family: 'Inconsolata';
  border-radius: 3px;
  display: block;
  padding: 5px 15px;
  width: 100%;
  // overflow-x: scroll;
  position: relative;

  pre {
    font-size: 14px;
    font-family: 'Inconsolata';
    white-space: pre-wrap;
    word-break: break-word;
    color: #546291;

    b {
      font-weight: 600;
      color: #1e2a5c;
    }
  }
}

.buttons {
  margin-top: 15px;
  button {
    margin-right: 20px;
  }
}

.higher {
  display: block;
  position: relative;
}

.box {
  position: absolute;
  top: -26px;
  background: white;
  padding: 15px 20px;
  // box-shadow: 0 2px 12px rgba(0, 0, 0, 0.09);
  border: 1px solid #e3e3e5;
  border-radius: 3px;
  // margin-top: 20px;
  z-index: 9;
  min-width: 450px;
  .tag {
    background: #d3fff5;
    color: #0e9e7f;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 3px;
  }

  .heading {
    font-weight: 600;
    margin: 10px 0;
    font-size: 16px;
  }

  .text {
    color: #333;
    opacity: 0.6;
    font-weight: 500;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .list {
    padding: 0px 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      margin: 10px 0;
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }

    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      color: #19cda6;
    }
  }
}

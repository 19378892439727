._ {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.icon {
  margin-right: 20px;
  color: #518bba;
}

.content {
}

.label {
  font-size: 13px;
  font-weight: 500;
  color: #999;
}

.value {
  font-size: 24px;
  font-weight: 600;
}
